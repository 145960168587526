import React from "react";
//import "./Footer.css"

function Footer () {
    return (
        <div className="footer__container" style={{height: "60px", display: "flex", alignItems: "center", backgroundColor: "#ff69b4", position: "sticky", top: "100%"}}>
        
        <div className="footer" style={{color: "white", display: "flex", justifyContent: "center", margin: "auto"}}>
             &#169; DecorativeLily LLC. All Rights Reserved.
        </div>
        </div>
    )
}

export default Footer