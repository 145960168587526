import React from 'react'
import "./Home.css"
import {products} from "../../utils/ProductsData"
//import {headerItems, products} from "../../utils/ProductsData"
import Product from '../../components/Product/Product';
//import BackToTop from '../../components/BackToTop/BackToTop';
import Carousel from "../../components/Banner"
//import SocialMedia from '../../components/SocialMedia';
//import { Link } from 'react-router-dom';
//import DecorativeLily from "../../images/DecorativeLily.jpg"

const Home = () => {
    return (
        <div style={{backgroundColor: "white"}}>
            <div className="">
                <div className="">
                   <Carousel />
                   {/*<div className="" style={{textAlign: "center"}}>
                    <div style={{marginTop: -8, textAlign: "center"}}>
                        <a href="/boxes" style={{color: "#ff69b4", fontSize: 25, textDecoration: "none"}}>Boxes</a>
                        </div>
                       <Link to="/boxes" style={{textDecoration: "none"}}>
                            <img src={DecorativeLily} alt="" style={{width: "75%"}}/>
                        </Link>
                        <div style={{textAlign: "center"}}>
                        <a href="/picnics" style={{color: "#ff69b4", fontSize: 25, textDecoration: "none"}}>Picnics</a>
                        </div>
                       <Link to="/picnics" style={{textDecoration: "none"}}>
                            <img src={DecorativeLily} alt="" style={{width: "75%"}}/>
                        </Link>
                    </div>*/}
                    <div className="home-row" style={{marginTop: 1}}>
                       {products.slice(0, 1).map((item) => (
                           <Product
                           key={item.id}
                           id={item.id}
                           title={item.title}
                           price={item.price}
                           rating={item.rating}
                           image={item.image}
                           //specification={item.specification}
                           //detail={item.detail}
                           seller={item.seller}
                       />
                       ))}
                    </div>
                    <div className="home-row">
                        {products.slice(1,2).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                                seller={item.seller}
                            />
                        ))}
                    </div>
                    <div className="home-row">
                        {products.slice(2,3).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                                seller={item.seller}
                            />
                        ))}
                    </div>
                    <div className="home-row">
                        {products.slice(3,4).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                                seller={item.seller}
                            />
                        ))}
                    </div>
                    <div className="home-row">
                        {products.slice(4,5).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                rating={item.rating}
                                image={item.image}
                                //specification={item.specification} 
                                //detail={item.detail}
                                seller={item.seller}
                            />
                        ))}
                    </div>
                    </div>
                    </div>
                </div>
    )
}

export default Home

/*
<Link to="/" style={{color: "white", fontSize: 25, textDecoration: "none"}}>
                <img className="header-logo" 
                src={decorativelily}
                alt="" 
                style={{height: 75, marginBottom: 10}}
                //src="https://hdclipartall.com/images/picnic-clipart--1943x1404.png"
                //alt=""
                />
            </Link>
*/

/*
import Slider from '../../components/Slider/Slider';
import Banner1 from "../../BannerImages/Banner1.JPG";
import Banner2 from "../../BannerImages/Banner2.JPG";
import Banner3 from "../../BannerImages/Banner3.JPG";
import Banner4 from "../../BannerImages/Banner4.JPG";
import Banner5 from "../../BannerImages/Banner5.JPG";
const bannerImages = [Banner1, Banner2, Banner3, Banner4, Banner5]
<Slider images={bannerImages} />
*/

/*
<div>
             <div className="item-container" style={{justifyContent: "space-between"}}>
                {headerItems && headerItems.map((item, index) => <p>{item}</p>)}
            </div>
            <div className="home">
*/