import React, {useState} from 'react'
import "./Contact.css"
import {Link} from "react-router-dom"
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firestore = firebase.firestore();

function Contact() {

  return (
    <div className="contact">
        <Link to ="/">
            <img className="contact__logo" src="" alt=""/>
        </Link>

      <div className="contact__container">
        <h1>Contact Us</h1>
        <ContactUs />
      </div>
    </div>
  );
}

      function ContactUs() {
        const contactRef = firestore.collection('contact');
        
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [phone, setPhone] = useState('');
        const [message, setMessage] = useState('');

        const sendContact = async (e) => {
          e.preventDefault();

          await contactRef.add({
            name: name,
            email: email,
            phone: phone,
            message: message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })

          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        }

      return (<>

      <div>
      <form onSubmit={sendContact} method="post" style={{textAlign: "center"}}>
      <input type="text" name="name" placeholder="Enter Name"  value={name} onChange={(e) => setName(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}} required/>
      <input type="text" name="email" placeholder="Enter Email"  value={email} onChange={(e) => setEmail(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}} required/>
      <input type="text" name="phone" placeholder="Enter Phone Number"  value={phone} onChange={(e) => setPhone(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}} required/>
      <input type="text" name="message" placeholder="Enter Message"  value={message} onChange={(e) => setMessage(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}} required/>
      <button className="contact__submitButton" type="submit" style={{color: "white"}}>Submit</button>
    </form>
      </div>
      </>)
}


export default Contact

/*
<div style={{textAlign: "center"}}>
            <section>
                <button>
                <a href="tel:8179753597" style={{color: "#ff69b4"}}>
                    Call
                </a>
                </button>
                <button>
                <a href="sms:8179753597" style={{color: "#ff69b4"}}>
                    Text
                </a>
                </button>
            </section>
        </div>
*/