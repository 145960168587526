import React from "react";
import { Link } from "react-router-dom";
//import "./NavBar.css"

function NavBar() {
  return (
    <div
      style={{
        height: "45px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        borderColor: "#ff69b4",
        position: "stick",
        zIndex: 100,
        justifyContent: "space-between",
        border: "1px solid #ff69b4",
      }}
    >
      <Link
        to="/about"
        style={{ marginLeft: 5, color: "#ff69b4", textDecoration: "none" }}
      >
        About
      </Link>
      <Link to="/booking" style={{ color: "#ff69b4", textDecoration: "none" }}>
        Booking
      </Link>
      <Link to="/contact" style={{ color: "#ff69b4", textDecoration: "none" }}>
        Contact
      </Link>
      <Link to="/faq" style={{ color: "#ff69b4", textDecoration: "none" }}>
        FAQ
      </Link>
      <Link to="/gallery" style={{ color: "#ff69b4", textDecoration: "none" }}>
        Gallery
      </Link>
      {/*<Link to="/package" style={{color: "#ff69b4",  textDecoration: "none"}}>Package</Link>*/}
      <Link to="/support" style={{ color: "#ff69b4", textDecoration: "none" }}>
        Support
      </Link>
      <Link to="/boxes" style={{ color: "#ff69b4", textDecoration: "none" }}>
        Boxes
      </Link>
      {/*<Link to="/picnics" style={{color: "#ff69b4", textDecoration: "none"}}>Picnics</Link>*/}
    </div>
  );
}

export default NavBar;
