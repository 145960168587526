import React from 'react'
import "./Checkout.css"
import {useSelector} from "react-redux"
import CheckoutProduct from '../../components/CheckoutProduct/CheckoutProduct';
import SubTotal from '../../components/SubTotal/SubTotal';

const Checkout = () => {
    const {basket, user} = useSelector((state) => state.data);
    return (
        <div className="checkout">
            <div clasName="checkout-left">
            <img 
                className="checkout-ad"
                src="https://images-na.ssl-images-amazon.com/images/G/02/UK_CCMP/TM/OCC_Amazon1._CB423492668_.jpg"
                alt=""
            />

        <div style={{color: "#ff69b4"}}>
            <h3>Hello { user ? user.email : "Guest"},</h3> 
            <h2 className="checkout-title">
                {basket.length === 0 
                ? "Your Shopping Basket Is Empty" 
                : "Your Shopping Basket"}
            </h2>
            {basket && basket.map((item) => (
                <CheckoutProduct 
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    image={item.image}
                    price={item.price}
                    rating={item.rating}
                    detail={item.detail}
                    seller={item.seller}
                />
            ))}
        </div>
        </div>
        <div className="checkout-right" style={{color: "#ff69b4"}}>
              <SubTotal />  
        </div>
      </div>
    )
}

export default Checkout

/*
<h3>Hello, {user?.email}</h3>
*/

/*
 <span className="header-option1" >Hello, {user ? user.email : "Guest"}{" "}</span>
                        <span className="header-option1">{user ? "Sign Out" : "Sign In"}</span>
*/
