const products = [
  /*{
    id: "1",
      title: "Birthday Box",
      price: 50,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Personalized birthday box. Items sold separately.",
      image:
        "/images/DecorativeLily13.jpg",
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
        seller: "DecorativeLily"
  },*/
];
  
  /*const headerItems = [
    "Home",
    "About",
    "Booking",
    "Contact",
    "FAQ",
    "Gallery",
    "Packages",
    "Support",
  ];*/
  
  export { products };
  //export { products, headerItems };

  /*
  {
      id: "1",
      title: "I Heart United States T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartUnitedStates.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "2",
      title: "I Heart Argentina T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "‎Test",
      image:
        "/images/IHeartArgentina.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "3",
      title: "I Heart Brazil T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartBrazil.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "4",
      title: "I Heart Canada T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartCanada.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "5",
      title:
        "I Heart China T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartChina.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "6",
      title: "I Heart Colombia T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartColombia.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "7",
      title: "I Heart England T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartEngland.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "8",
      title:
        "I Heart France T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartFrance.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "9",
      title:
        "I Heart Germany T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartGermany.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "10",
      title:
        "I Heart India T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartIndia.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "11",
      title:
        "I Heart Italy T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartItaly.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "12",
      title:
        "I Heart Japan T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartJapan.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "13",
      title:
        "I Heart Korea T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartKorea.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "14",
      title:
        "I Heart Mexico T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartMexico.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "15",
      title:
        "I Heart Spain T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartSpain.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "16",
      title:
        "I Heart The Phillipines T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartThePhillipines.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "17",
      title:
        "I Heart Ukraine T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartUkraine.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "18",
      title:
        "I Heart United Kingdom T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/IHeartUnitedKingdom.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "19",
      title:
        "Korean Hello T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanHello.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "20",
      title:
        "Korean I Love Dumplings T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanILoveDumplings.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "21",
      title:
        "Korean I Love Kimchi T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanILoveKimchi.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "22",
      title:
        "Korean I Love Korea T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanILoveKorea.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "23",
      title:
        "Korean I Love Soju T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanILoveSoju.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "24",
      title:
        "Korean I Love You T-Shirt",
      price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanILoveYou.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
    {
      id: "25",
      title:
        "Korean K-Drama Is My Trauma T-Shirt",
        price: 20,
      rating: 5,
      specification: [
        "Test",
      ],
      detail:
        "Test",
      image:
        "/images/KoreanKDramaIsMyTrauma.JPEG"
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    },
  */