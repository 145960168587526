import React from 'react'
import "./Picnics.css"
//import {headerItems, products} from "../../utils/ProductsData"
import {products} from ".././utils/ProductsData"
import Product from '../components/Product/Product'
//import BackToTop from '../../components/BackToTop/BackToTop';
//import Carousel from "../../components/Banner"
//import SocialMedia from '../../components/SocialMedia';

//import {products} from "../../utils/ProductsData"
//import Product from '../../components/Product/Product';

const Picnics = () => {
    return (
        <div style={{backgroundColor: "white"}}>
            <div className="home">
                <div className="home-container">
                   {/*<Carousel />*/}
                   <div className="home-row" style={{marginTop: 1}}>
                       {products.slice(0, 1).map((item) => (
                           <Product
                           key={item.id}
                           id={item.id}
                           title={item.title}
                           price={item.price}
                           //rating={item.rating}
                           image={item.image}
                           //specification={item.specification}
                           //detail={item.detail}
                       />
                       ))}
                    </div>
                    <div className="home-row">
                        {products.slice(1,2).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                //rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                            />
                        ))}
                    </div>
                    <div className="home-row">
                        {products.slice(2,3).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                //rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                            />
                        ))}
                    </div>
                    <div className="home-row">
                        {products.slice(3,4).map((item) => (
                            <Product
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                //rating={item.rating}
                                image={item.image}
                                //specification={item.specification}
                                //detail={item.detail}
                            />
                        ))}
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default Picnics

/*
import Slider from '../../components/Slider/Slider';
import Banner1 from "../../BannerImages/Banner1.JPG";
import Banner2 from "../../BannerImages/Banner2.JPG";
import Banner3 from "../../BannerImages/Banner3.JPG";
import Banner4 from "../../BannerImages/Banner4.JPG";
import Banner5 from "../../BannerImages/Banner5.JPG";
const bannerImages = [Banner1, Banner2, Banner3, Banner4, Banner5]
<Slider images={bannerImages} />
*/

/*
<div>
             <div className="item-container" style={{justifyContent: "space-between"}}>
                {headerItems && headerItems.map((item, index) => <p>{item}</p>)}
            </div>
            <div className="home">
*/