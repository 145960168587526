import React, {useState, useEffect} from 'react'
import "./Payment.css"
import {useSelector, useDispatch} from "react-redux"
import CurrencyFormat from "react-currency-format"
import CheckoutProduct from "../../components/CheckoutProduct/CheckoutProduct"
import {getBasketTotal} from "../../utils/BasketTotal"
import {Link, useNavigate} from "react-router-dom"
import {db} from "../../utils/firebase"
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js"
import axios from "../../utils/axios"
import { setBasketEmpty } from '../../redux/actions'

const Payment = () => {
    const {basket, user} = useSelector((state) => state.data);

    let dispatch = useDispatch();

    let navigate = useNavigate();

    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState("");
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState(true);

    useEffect(() => {
        const getClientSecret = async () => {
            const response = await axios({
                method: "POST",
                url: `/payments/create?total=${getBasketTotal(basket) * 100}`,
            });
            setClientSecret(response.data.clientSecret);
        }
        getClientSecret();
    }, [basket]);

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        }).then(({paymentIntent}) => {
            db.collection("users")
            .doc(user && user.uid)
            .collection("orders")
            .doc(paymentIntent.id)
            .set({
                basket: basket,
                amount: paymentIntent.amount,
                created: paymentIntent.created,
            });
            setSucceeded(true);
            setError(null);
            setProcessing(false);
            dispatch(setBasketEmpty());
            navigate("/success")
        });
}

    const handleChange = (e) => {
        setDisabled(e.empty);
        setError(e.error ? e.error.message : "");
    };
    return (
        <div className="payment">
            <div className="payment-container" style={{color: "#ff69b4"}}>
                <h1>Checkout {<Link to="/checkout" style={{color: "#ff69b4"}}>{basket.length} item(s)</Link>}</h1>
                <div className="payment-section">
                    <div className="payment-title">
                        <h3>Delivery Address</h3>
                    </div>
                    <div className="payment=address" style={{color: "#ff69b4"}}>
                        <p>{user && user.email}</p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
                <div className="payment-section">
                    <div className="payment-title">
                        <h3>Review Items And Delivery</h3>
                    </div>
                    <div className="payment-items">
                        {basket && basket.map((item) => (
                            <CheckoutProduct 
                            id={item.id}
                            title={item.title}
                            image={item.image}
                            price={item.price}
                            rating={item.rating}
                            seller={item.seller}
                            detail={item.detail}
                            />
                        ))}
                    </div>
                </div>
                <div className="payment-section">
                    <div className="payment-title">
                        <h3>Payment Method</h3>
                    </div>
                    <div className="payment-details">
                        <form onSubmit={handleSubmit}>
                            <CardElement onChange={handleChange} />
                            <div className="payment-priceContainer">
                            <CurrencyFormat
                                renderText={(value) => (
                                    <>
                                       <h3>Order Total: {value}</h3>
                                    </>
                                )}
                                decimalScale={2}
                                value={getBasketTotal(basket)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                />
                                <button disabled={processing || disabled || succeeded}>
                                    <span>{processing ? <p>Processing</p> : "Buy Now"}</span>
                                </button>
                            </div>
                            {error && <div>{error}</div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment
