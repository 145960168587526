import React, {useState} from 'react'
import "./Booking.css"
import {Link} from "react-router-dom"
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firestore = firebase.firestore();

function Booking() {
  return (
    <div className="body" style={{backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Link to="/">
            <img className="booking__logo" src="" alt=""/>
        </Link>

        <div className="booking__container">
            <h1>Booking Request</h1>
            <BookingRequest />
            </div>
    </div>
  );
}

function BookingRequest () {
  const bookingRef = firestore.collection('booking');
        
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [occassion, setOccassion] = useState('');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [time, setTime] = useState('');
  const [guestCount, setGuestCount] = useState('');
  const [theme, setTheme] = useState('');
  const [addOn, setAddOn] = useState('');
  const [image, setImage] = useState('');
  const [message, setMessage] = useState('');

  const sendBooking = async (e) => {
    e.preventDefault();

    await bookingRef.add({
      name: name,
      email: email,
      phone: phone,
      occassion: occassion,
      date: date,
      location: location,
      time: time,
      guestCount: guestCount,
      theme: theme,
      addOn: addOn,
      image: image,
      message: message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })

    setName('');
    setEmail('');
    setPhone('');
    setOccassion('');
    setDate('');
    setLocation('');
    setTime('');
    setGuestCount('');
    setTheme('');
    setAddOn('');
    setImage('');
    setMessage('');
}

return(<>
    <div>
     <form onSubmit={sendBooking} method="post" style={{textAlign: "center"}}>
         <input type="text" name="name" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}} required/>
         <input type="text" name="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
         <input type="text" name="phone" placeholder="Enter Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
         <input type="text" name="occassion" placeholder="Enter Occassion" value={occassion} onChange={(e) => setOccassion(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
         <input type="text" name="date" placeholder="Enter Date" value={date} onChange={(e) => setDate(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
         <input type="text" name="location" placeholder="Enter Location" value={location} onChange={(e) => setLocation(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>
         <input type="text" name="time" placeholder="Enter Time" value={time} onChange={(e) => setTime(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
         <input type="text" name="guestCount" placeholder="Enter Guest Count" value={guestCount} onChange={(e) => setGuestCount(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
         <input type="text" name="theme" placeholder="Enter Theme" value={theme} onChange={(e) => setTheme(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
         <input type="text" name="addOns" placeholder="Enter Add-Ons" value={addOn} onChange={(e) => setAddOn(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
         <input type="text" name="image" placeholder="Enter Image" value={image} onChange={(e) => setImage(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
         <input type="text" name="message" placeholder="Enter Message" value={message} onChange={(e) => setMessage(e.target.value)} style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/> 
        <button className="booking__submitButton" type="submit" style={{color: "white"}}>Submit</button>
        </form>
        </div>
        </>)
}

export default Booking
