import React from 'react'

function FAQ() {
  return (
    <div style={{ backgroundColor: "white" }}>
      {/*<div style={{ textAlign: "center", color: "#ff69b4", marginTop: 50, fontSize: 35 }}>
        FAQ
          <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: How do I schedule a picnic?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: Please fill out a booking request and we will contact you within 24-48 hours with further details.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: How much does a picnic cost?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: The cost of a picnic depends on the package selected, number of guests, and add-on items requested. Deposits are due at the time of booking with the remaining balance due two weeks prior to the scheduled date and time.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: What does my picnic include?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: All picnics include table(s), floor mats, pillows for seating, charger plates, plastic plates, napkins, plasticware, cups, tablerunner, table silk, flower decorations, and teepee.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: What if I need to cancel my picnic?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: Deposits are 100% refundable with a 30+ day notice. Deposits are non-refundable with cancellation notices less than 30 days. If cancellation is due to bad weather, picnic can be rescheduled to another available date and time.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: What if we are expecting bad weather/rain on my scheduled picnic date/time?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: In the event of inclement weather, we will work with you on rescheduling your picnic to another available date and time.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: Where can I have a picnic?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: Picnic locations include homes, parks, lakes, etc that do not require permits or any additional fees. Easy accessibility to parking and picnic setup areas that are not more than fifty feet away from parking.
          </div>
        <div style={{ marginTop: 25, fontSize: 25 }}>
          Q: What areas of DFW do you service?
          </div>
        <div style={{ marginTop: 5, fontSize: 25 }}>
          A: We service the following areas in DFW: Argyle, Bedford, Colleyville, Euless, Flower Mound, Grapevine, Haltom, Haslet, Justin, Keller, Fort Worth (NW), North Richland Hills, Roanoke, Saginaw, Southlake, Trophy Club, and Watauga. We also serve surrounding areas for an extra charge.
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: How long is a picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: Picnics are slotted for 2 hours with the option to extend for an additonal fee.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: How far in advance do I need to schedule picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: ASAP as dates and times are filling up quickly.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: Why a Decorativelily picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: Special occassions do not happen every day. We can help provide a memorable picturesque moment that you will remember for a lifetime.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: Is alcohol allowed?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: We do not provide alcohol or assume responsibility for violations.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: Do I need to cleanup after picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: No. We will take care of the setup and cleanup. All you need to do is show up!
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: Do you provide food/drinks for picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: You are more than welcome to bring your own foods and drinks. For an additional fee you can request food/drink as well as add-on items.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: How do I pay for picnic?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: A deposit is due at the time of booking. Remaining balance is due two weeks before your scheduled time of event. All food or personalized items are due at the time of booking and are non-refundable.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: What if I damage picnic item(s)?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: We expect items to be maintained in the same condition as delivered. A charge will apply for any damaged items.
          </div>
          <div style={{ marginTop: 25, fontSize: 25 }}>
            Q: What if I finish picnic early?
          </div>
          <div style={{ marginTop: 5, fontSize: 25 }}>
            A: You will be responsible for the picnic items during the two hour contract of your picnic. If you decide to end your picnic early, a 30 minute notice will be required so we can return to reassume responsibility of the picnic items. Unattended items during your contracted time will be your full responsibility. You will be charged for damaged/missing items due to negligence.</div>
        </div>
        <div style={{ marginTop: 25, fontSize: 25, marginBottom: 50 }}>
          If you have any other questions please email us at DecorativeLily@gmail.com
          </div>
  </div>*/}
    </div>
  )
}

export default FAQ
