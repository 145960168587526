import React, { useRef, useState } from 'react';
import "./Support.css"
import {Link} from "react-router-dom"
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firestore = firebase.firestore();

function Support() {

  return (
    <div className="support">
      <Link to="/">
        <img className="support__logo" src="" alt=""
        />
      </Link>

      <div className="support__container">
        <h1 style={{textAlign: "center"}}>Support</h1>

      <section>
        <ChatRoom />
      </section>
      </div>
      </div>
  );
}

function ChatRoom() {
  const dummy = useRef();
  const supportRef = firestore.collection('support');
  const query = supportRef.orderBy('createdAt').limit(25);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    await supportRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <SupportMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>
  
  <div>
      <h1></h1>
    <form onSubmit={sendMessage} style={{textAlign: "center"}}>
        <h5></h5>
      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Enter Message" style={{color: "#ff69b4", height: "30px", marginBottom: "10px", backgroundColor: "white", width: "98%", textAlign: "center", marginTop: 5}}/>

      <button className="support__submitButton" type="submit" disabled={!formValue} style={{color: "white"}}>Submit</button>
    </form>
    </div>
  </>)
}

function SupportMessage(props) {
  const { text} = props.message;

  return (<>
    <div className={`message`} style={{color: "#ff69b4", textAlign: "center", marginTop: 5}}>
      <p style={{marginTop: 10}}>{text}</p>
    </div>
  </>)
}

export default Support

/*admin.firestore().collection('notification').add({
  to: 'dominiquecastaneda@yahoo.com',
  message: {
    subject: 'Support',
    html: 'Test',
  },
})*/

/*
<form onSubmit="sendEmail();">
*/

/*
<script src="https://smtpjs.com/v3/smtp.js"></script>
<script 
function sendEmail(){
  Email.send({
    //SecureToken: "",
    Host: "smtp.gmail.com",
    Username: "test@gmail.com",
    Password: "Test#1"
    To: "dominiquemcastaneda@gmail.com",
    From: document.getElementById("email").value,
    Subject: "Test",
    Body : "Message: " + document.getElementById("message").value
  }).then(
    message => alert("Message Sent")
  );
  }
  </script>
*/