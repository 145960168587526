import React from 'react'
import {useNavigate} from "react-router-dom"

const Success = () => {
    const navigate = useNavigate();
    return (
        <div>
            <main>
                <div>
                    <div>
                        <h1 style={{color: "#ff69b4", textAlign:"center", marginTop: "25%"}}>
                            Thank you for shopping with DecorativeLily! Your order has been confirmed.
                        </h1>
                    </div>
                    <p style={{color: "#ff69b4", textAlign:"center"}}>
                        Please click the link below to review your order(s).
                    </p>
                    <div style={{textAlign:"center"}}>
                    <button onClick={() => navigate("/orders")} style={{backgroundColor: "#ff69b4", borderRadius:"2px", width:"300px", height:"30px", border:"1px solid", marginTop:"10px", color:"white", borderBottom: "1px solid lightgray"}}>
                       My Orders
                    </button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Success