import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner(){
    return (
        <div className="relative">
            <div className="absolute w-full">
            <Carousel 
                autoPlay
                infiniteLoop
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                interval={5000}
            >
                <div>
                    <img loading="lazy" src="images/DecorativeLily.jpg" alt="Decorativelily" style={{height: 300, width: 1000}} />
                </div>
                {/*<div>
                    <img loading="lazy" src="images/DecorativeLily2.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily3.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily4.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily5.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily6.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily7.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily8.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily9.jpg" alt="Decorativelily" />
                </div>
                <div>
                    <img loading="lazy" src="images/DecorativeLily10.jpg" alt="Decorativelily" />
    </div>*/}
                {/*
                <div>
                    <img loading="lazy" src="images/IHeartUnitedStates.JPEG" alt="I Heart United States T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartArgentina.JPEG" alt="I Heart Argentina T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartBrazil.JPEG" alt="I Heart Brazil T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartCanada.JPEG" alt="I Heart Canada T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartChina.JPEG" alt="I Heart China T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartColombia.JPEG" alt="I Heart Colombia T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartEngland.JPEG" alt="I Heart England T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartFrance.JPEG" alt="I Heart France T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartGermany.JPEG" alt="I Heart Germany T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartIndia.JPEG" alt="I Heart India T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartItaly.JPEG" alt="I Heart Italy T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartJapan.JPEG" alt="I Heart Japan T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartKorea.JPEG" alt="I Heart Korea T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartMexico.JPEG" alt="I Heart Mexico T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartSpain.JPEG" alt="I Heart Spain T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartThePhillipines.JPEG" alt="I Heart The Phillipines T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartUkraine.JPEG" alt="I Heart Ukraine T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/IHeartUnitedKingdom.JPEG" alt="I Heart United Kingdom T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanHello.JPEG" alt="Korean Hello T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanILoveDumplings.JPEG" alt="Korean I Love Dumplings T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanILoveKimchi.JPEG" alt="Korean I Love Kimchi T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanILoveKorea.JPEG" alt="Korean I Love Korea T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanILoveSoju.JPEG" alt="Korean I Love Soju T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanILoveYou.JPEG" alt="Korean I Love You T-Shirt" />
                </div>
                <div>
                    <img loading="lazy" src="images/KoreanKDramaIsMyTrauma.JPEG" alt="Korean K-Drama Is My Trauma T-Shirt" />
                </div>
                {/*<div>
                    <img loading="lazy" src="images/Banner1.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner2.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner3.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner4.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner5.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner6.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner7.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner8.JPG" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Banner9.JPG" alt="" />
                </div>*/}
            </Carousel>
            </div>
        </div>
    );
}

export default Banner