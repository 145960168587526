import React, {useEffect} from 'react'
import './App.css';
import {Routes, BrowserRouter, Route} from "react-router-dom"
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Login from "./pages/Login/Login"
import Register from './pages/Register/Register';
import {useDispatch} from "react-redux"
import {auth} from "./utils/firebase"
import { setuser } from './redux/actions';
import SingleProduct from './pages/SingleProduct/SingleProduct';
import Checkout from './pages/Checkout/Checkout';
import Payment from './pages/Payment/Payment';
import {loadStripe} from "@stripe/stripe-js"
import {Elements} from "@stripe/react-stripe-js"
import Orders from './pages/Orders/Orders';
import Success from './pages/Success/Success';
import About from './pages/About';
import Booking from './pages/Booking';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Gallery from './pages/Gallery';
//import Package from './pages/Package';
import Support from './pages/Support';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import QR from './components/QR';
import Boxes from './pages/Boxes'
import Picnics from './pages/Picnics'

const promise = loadStripe(
  "pk_test_51LPswGCVHojcJS8zdVe0ICRAMrUHmY37ORA8dyMpduoLscq3rCblOAOzdQAPc2ZjL7eoFfKGlRDbDmU9SNYcB02w00gzS0dM6h"
  );


function App() {
  let dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(setuser(authUser));
      } else {
        dispatch(setuser(null));
      }
    });
  }, [dispatch]);

  return (
    <BrowserRouter>
    <div className="App">
      <Header />
      <NavBar />
      <body className="body" style={{minHeight: "100vh"}}>
      <Routes>
      <Route path="/about" element={<About />}>
      </Route>
      <Route path="/booking" element={<Booking />}>
      </Route>
      <Route path="/contact" element={<Contact />}>
      </Route>
      <Route path="/faq" element={<FAQ />}>
      </Route>
      <Route path="/gallery" element={<Gallery />}>
      </Route>
      {/*<Route path="/package" element={<Package />}>
      </Route>*/}
      <Route path="/support" element={<Support />}>
      </Route>
      <Route path="/success" element={<Success />}>
      </Route>
      <Route path="/boxes" element={<Boxes />}>
      </Route>
      <Route path="/picnics" element={<Picnics />}>
      </Route>
      <Route path="/orders" element={<Orders />}>
      </Route>
      <Route path="/payment" element={<Elements stripe={promise}><Payment /></Elements>} />
      <Route path="/checkout" element={<Checkout />}>
      </Route>
      <Route path="/product/:id" element={<SingleProduct />}>
      </Route>
      <Route path="/register" element={<Register />}>
      </Route>
      <Route path="/login" element={<Login />}>
      </Route>
      <Route path="/qr" element={<QR />}>
      </Route>
      <Route path="/" element={<Home />}>
      </Route>
      </Routes>
      </body>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;

