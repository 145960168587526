import React from 'react'
import QRCode from 'react-qr-code';
import { useState } from "react";

function QR() {
    const [text, setText] = useState("");

    /*function generateQR(e){
        setText()
    }*/

    function handleChange(e){
        setText(e.target.value)
    }
  return (
    <div style={{textAlign: "center"}}>
        <h1 style={{color: "#ff69b4"}}>QR Code Generator</h1>
        <QRCode value={text} style={{marginTop: 25}} />

        <div className="input-here">
            <input type="text" value={text} onChange={(e)=>{handleChange(e)}} style={{color: "#ff69b4", height: "30px", marginTop: "10px", width:"50%", backgroundColor:"white", textAlign:"center"}} />
            <button style={{backgroundColor: "#ff69b4", border: "1px solid", borderRadius: "2px", color: "white", height: "40px", width: "120px"}}>Submit</button>
        </div>
    </div>
  )
}

export default QR