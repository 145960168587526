import React from 'react'

function Gallery() {
    return (
        <div style={{ backgroundColor: "white", textAlign: "center", color: "#ff69b4", fontSize: 35 }}>
            {/*<img src="/images/DecorativeLily1.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily2.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily3.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily4.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily5.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily6.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily7.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily8.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
            <img src="/images/DecorativeLily9.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>
    <img src="/images/DecorativeLily10.jpg" alt="DecorativeLily" style={{ height: "auto", width: "100%", marginTop: 10 }}></img>*/}
        </div>
)}

export default Gallery