import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyC9-Rjql4hQJMxJoxh-RxdtNl1-HEUC6Ug",
    authDomain: "decorativelilyllc.firebaseapp.com",
    databaseURL: "",
    projectId: "decorativelilyllc",
    storageBucket: "decorativelilyllc.appspot.com",
    messagingSenderId: "1025984821635",
    appId: "1:1025984821635:web:ed4819819dafdf4ded1eb6",
    measurementId: "G-DGZ9N7CYRZ",
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };